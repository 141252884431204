import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ReferenciaOnline from "../utilities/ReferenciaOnline";

import "./Formulario.css";

const Formulario = ({
  conceptos,
  alumno,
  precio,
  cambiarPrecio,
  datospago,
  cambiarDatosPago,
  claves,
  cambiarClaves,
  checked,
  cambiarChecked,
  cambiarPagar,
  pagar,
  cambiarTablaConceptos,
  tablaConceptos,
}) => {
  let i = 0;

  useEffect(() => {
    cambiarPagar(false);
    cambiarPrecio(0);
    cambiarTablaConceptos([]);
    cambiarChecked({
      c1: false,
      c2: false,
      c3: false,
      c4: false,
      c5: false,
      c6: false,
      c7: false,
      c8: false,
      c9: false,
      c10: false,
      c11: false,
      c12: false,
      c13: false,
      c14: false,
      c15: false,
      c16: false,
      c17: false,
      c18: false,
      c19: false,
      c20: false,
      c21: false,
    });
  }, []);

  const [error, cambiarError] = useState(false);

  const [seleccionados, cambiarSeleccionados] = useState(0);
  let tabla = [];

  function incrementarContador() {
    return i++;
  }

  const removeItemFromArr = (arr, item) => {
    return arr.filter((e) => e !== item);
  };

  const removeItemFromObjArr = (arr, item) => {
    return arr.filter((e) => e.clave !== item);
  };

  const onCLickCheck = (concepto) => {
    if (concepto.checked) {
      cambiarChecked({ ...checked, [concepto.id]: false });
      cambiarPrecio(precio - concepto.precio);
      cambiarSeleccionados(seleccionados - 1);
      cambiarClaves(removeItemFromArr(claves, concepto.clave));
      cambiarTablaConceptos(
        removeItemFromObjArr(tablaConceptos, concepto.clave)
      );
    } else {
      if (seleccionados === 5) return;
      cambiarChecked({ ...checked, [concepto.id]: true });
      cambiarPrecio(precio + concepto.precio);
      cambiarSeleccionados(seleccionados + 1);
      cambiarClaves([...claves, concepto.clave]);
      cambiarTablaConceptos([
        ...tablaConceptos,
        {
          concepto: concepto.concepto,
          clave: concepto.clave,
          precio: concepto.precio.toString(),
        },
      ]);
    }
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    //Validar checados
    if (claves.length === 0) {
      cambiarError(true);
      return;
    }

    cambiarError(false);

    //

    let importe = precio.toString().concat(".00");
    let refaux = alumno.codigo[7].concat(alumno.codigo[8]);
    for (let i = 0; i < claves.length; i++) {
      refaux = refaux.concat(claves[i]);
    }
    while (refaux.length < 12) {
      refaux = refaux.concat("00");
    }
    refaux = refaux.concat(alumno.codigo);
    let base64 = await ReferenciaOnline(refaux, importe)
    cambiarDatosPago({
      referencia: refaux,
      importe: importe,
      key: base64,
    });

    cambiarPagar(true);
  };

  return alumno.codigo === "" ? (
    <Redirect to="/login" />
  ) : (
    <div className="Formulario container">
      <h2>Selecciona los conceptos a pagar (Máximo 5)</h2>
      <form onSubmit={HandleSubmit}>
        <div className="Formulario-flex">
          <table>
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Costo</th>
              </tr>
            </thead>
            <tbody>
              {conceptos.map((concepto) => (
                <tr
                  key={concepto.clave}
                  className={
                    incrementarContador() % 2 != 0 ? "Formulario-par" : null
                  }
                >
                  <td className="Formulario-concepto">{concepto.concepto}</td>
                  <td>${concepto.precio}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={concepto.id}
                      checked={concepto.checked}
                      onChange={() => onCLickCheck(concepto)}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="">
            <div className="Formulario-total">
              <h4>Total: ${precio}.00</h4>
            </div>
            {error ? (
              <div className="Formulario-error">
                <p>Es necesario seleccionar al menos un concepto</p>
              </div>
            ) : null}
            <input className="Formulario-boton" type="submit" value="Pagar" />
          </div>
        </div>
      </form>
      {pagar ? <Redirect to="/formadepago" /> : null}
    </div>
  );
};

export default Formulario;
