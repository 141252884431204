import DigitoverBBVA from "./DigitoverBBVA";

const GenerarReferencia = ({ matricula, servicio, monto }) => {
  let referencia = "";
  referencia = matricula[7].concat(matricula[8]); //2 digitos plantel
  referencia = referencia.concat(servicio); //2 servicio
  referencia = referencia.concat(matricula.slice(0, 7)); //13 digitos matricula, se quita el digito 8
  referencia = referencia.concat(matricula.slice(8, matricula.length));
  const BBVA = DigitoverBBVA({ referencia, monto });

  return { BBVA };
};

//8 y 9 numero de plantel

export default GenerarReferencia;
