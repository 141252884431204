import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import "./Tipo.css";
import Boton_3 from "../img/Boton_3.png";
import Boton_4 from "../img/Boton_4.png";
import Boton_5 from "../img/Boton_5.png";

const Tipo = ({ alumno }) => {
  return alumno.codigo === "" ? (
    <Redirect to="/login" />
  ) : (
    <div className="Tipo container">
      <div className="Tipo-flex">
        <Link className="Tipo-seleccion" to="/formulario-examenes">
          <img className="Tipo-img" src={Boton_3} alt="" />
        </Link>
        <Link className="Tipo-seleccion" to="/formulario-diplomas">
          <img className="Tipo-img" src={Boton_5} alt="" />
        </Link>
        <Link className="Tipo-seleccion" to="/formulario-otros">
          <img className="Tipo-img" src={Boton_4} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default Tipo;
